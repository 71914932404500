import React, { /*lazy,*/ Suspense, useEffect } from "react";
// import LiteYouTubeEmbed from "lite-youtube-embed";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
// const YouTube = lazy(() => import("react-youtube"));

const Videos = () => {
  // const opts = {
  //   height: "200",
  //   width: "100%",

  //   playerVars: {
  //     autoplay: 0,
  //     modestbranding: 0,
  //   },
  // };
  useEffect(() => {}, []);

  return (
    <section id="videosYoutube" className="why_section layout_padding">
      <div className="container-fluid">
        <div className="custom_heading-container">
          <center>
            <h2>Vive nuestras maravillosas experiencias</h2>
          </center>
        </div>
        <center></center>
        <section className="client_section layout_padding">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container-fluid">
                  <div className="row" style={{ marginLeft: "0%" }}>
                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <LiteYouTubeEmbed id="https://www.youtube.com/watch?v=SXrSTCJYPXQ" /> */}

                        <LiteYouTubeEmbed
                          params="controls=0&start=10&end=30&modestbranding=2&rel=0&enablejsapi=1"
                          id="cyd2elFIJPo"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                        {/* <YouTube videoId="cyd2elFIJPo" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="GLvc57YPQN8"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />

                        {/* <YouTube videoId="GLvc57YPQN8" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="aJKhYmgRg54"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />

                        {/* <YouTube videoId="aJKhYmgRg54" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="BF3-dXiA_k0"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                        {/* <YouTube videoId="BF3-dXiA_k0" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="xvgZmmrJ3Yg"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                        {/* <YouTube videoId="xvgZmmrJ3Yg" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="p2DDd7s3_C0" opts={opts} /> */}
                        <LiteYouTubeEmbed
                          id="p2DDd7s3_C0"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="cpf29L8IXas"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />

                        {/* <YouTube videoId="cpf29L8IXas" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="V0CHYFcwcTM"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />

                        {/* <YouTube videoId="V0CHYFcwcTM" opts={opts} /> */}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        <LiteYouTubeEmbed
                          id="n-k3g8IOq4g"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />

                        {/* <YouTube videoId="n-k3g8IOq4g" opts={opts} /> */}
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="Seo0lxA3mKU" opts={opts} /> */}
                        <LiteYouTubeEmbed
                          id="Seo0lxA3mKU"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="EYnvKe0t-i0" opts={opts} /> */}

                        <LiteYouTubeEmbed
                          id="EYnvKe0t-i0"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3 mb-1">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="gCnU2vPub2A" opts={opts} /> */}

                        <LiteYouTubeEmbed
                          id="gCnU2vPub2A"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="PPo3xnC20-s" opts={opts} /> */}
                        <LiteYouTubeEmbed
                          id="PPo3xnC20-s"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="yz3Q1TwTdpo" opts={opts} /> */}

                        <LiteYouTubeEmbed
                          id="yz3Q1TwTdpo"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="jxXoyth2DZs" opts={opts} /> */}

                        <LiteYouTubeEmbed
                          id="jxXoyth2DZs"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>

                    <div className="col-lg-3">
                      <Suspense fallback={<div>Loading...</div>}>
                        {/* <YouTube videoId="NxJ6h6y6xfk" opts={opts} /> */}

                        <LiteYouTubeEmbed
                          id="NxJ6h6y6xfk"
                          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span className="sr-only">Next</span>
            </a>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Videos;
